import { ProjectInfoSupplierUI } from "@nexploretechnology/concreting-core-client/supplier-ui-client/supplier.ui.dto";
import { Table } from "antd";
import { projectInfoColumns } from "./ProjectInfoTable.columns";
import styles from './ProjectInfoTable.module.css';

interface ProjectInfoTableProps {
    projectInfo: ProjectInfoSupplierUI[];
    orderId:string;
}

const ProjectInfoTable = ({projectInfo, orderId}: ProjectInfoTableProps) => {

    return (
        <>
            <Table
                style={{ verticalAlign: 'initial'}}
                rowClassName={styles.projectInfoTableRow}
                data-testid="projects-info-table"
                pagination={false}
                rowKey={'projectId'}
                columns={projectInfoColumns({
                    orderId: orderId
                })}
                dataSource={projectInfo}
            />
        </>
    );
}

export default ProjectInfoTable;