import { CopyOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { AddressSupplierUI, ProjectInfoSupplierUI } from '@nexploretechnology/concreting-core-client/supplier-ui-client/supplier.ui.dto';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import InfoNotification from 'src/app-react/components/Notification/InfoNotification';

interface OrderMonitoringColumnsProps {
    orderId: string
}

export const projectInfoColumns: (props: OrderMonitoringColumnsProps) => ColumnsType<ProjectInfoSupplierUI> = (
    props: OrderMonitoringColumnsProps
) => {
    const { orderId } = props;

    return [
        {
            title: <Trans>Project Name</Trans>,
            dataIndex: 'projectName',
            key: 'projectName'
        },

        {
            title: <Trans>Project Number</Trans>,
            dataIndex: 'projectNumber',
            key: 'projectNumber'
        },

        {
            title: <Trans>Delivery Address</Trans>,
            dataIndex: 'deliveryAddress',
            key: 'deliveryAddress',
            render: (deliveryAddress: AddressSupplierUI) => {
               return  (
                    <>
                    <Trans>Name: </Trans> {deliveryAddress.name} <br/>
                    <Trans>City: </Trans> {deliveryAddress.city} <br/>
                    <Trans>Street: </Trans> {deliveryAddress.street} <br/>
                    <Trans>Post Code: </Trans> {deliveryAddress.postCode} <br/>
                    <Trans>Building Number: </Trans> {deliveryAddress.buildingNumber}
                    </>
                );
            }

        },

        {
            title: <Trans>Billing Address</Trans>,
            dataIndex: 'billingAddress',
            key: 'billingAddress',
            render: (billingAddress: AddressSupplierUI) => {
               return  (
                    <>
                        <Trans>Name: </Trans> {billingAddress.name} <br/>
                        <Trans>City: </Trans> {billingAddress.city} <br/>
                        <Trans>Street: </Trans> {billingAddress.street} <br/>
                        <Trans>Post Code: </Trans> {billingAddress.postCode} <br/>
                        <Trans>Building Number: </Trans> {billingAddress.buildingNumber}
                    </>
                );
            }

        },

        {
            title: <Trans>Order ID</Trans>,
            dataIndex: 'orderId',
            key: 'orderId',
            render: (_) => (
                <>
                {`${orderId}`}
                <br />
                <Button
                    key="copyOrder"
                    data-testid='copy-btn'
                    type="link"
                    size="middle"
                    onClick={() => onCopyOrder(orderId)}>
                    <CopyOutlined /> <Trans>Copy order ID</Trans>
                </Button>
                </>
            )
        },
    ];
};

function onCopyOrder(orderId: string) {
    navigator.clipboard.writeText(orderId);
    InfoNotification({ message: t`Order ID copied to clipboard`, description: '' });
}
