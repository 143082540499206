import { Route, Routes, useParams } from 'react-router-dom';
import OrderComparisonPage from './business-page/OrderComparison/OrderComparisonPage';
import OrderDetailsPage from './business-page/OrderDetails/OrderDetailsPage';
import OrderMonitoringPage from './business-page/OrderMonitoring/OrderMonitoringPage';
import NotFound from './components/NotFound/NotFound';
import { SupplierProvider } from './providers/SupplierProvider';


const OrderMonitoringHandler = () => {
    return (
            <OrderMonitoringPage></OrderMonitoringPage>
    );
};


const OrderDetailsHandler = () => {

    const { companyId, projectId, orderId } = useParams();

    return (
            <OrderDetailsPage companyId= {companyId} projectId={projectId} orderId={orderId}></OrderDetailsPage>
    );
};

const OrderComparisonHandler = () => {

    const { companyId, projectId, orderId } = useParams();

    return (
            <OrderComparisonPage companyId= {companyId} projectId={projectId} orderId={orderId}></OrderComparisonPage>
    );
};


const AppRouter = () => {
    return (
        <SupplierProvider>
            <Routes>
                <Route path="/" element={<OrderMonitoringHandler />} />
                <Route path="/order-monitoring" element={<OrderMonitoringHandler />} />
                <Route path="/company/:companyId/project/:projectId/order/:orderId/details" element={<OrderDetailsHandler />} />
                <Route path="/company/:companyId/project/:projectId/order/:orderId/comparison" element={<OrderComparisonHandler />} />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </SupplierProvider>
    );
};

export default AppRouter;