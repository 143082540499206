import moment from 'moment';

export function isFutureHour(hour: number): boolean {
    return hour > moment().hour();
}

export function isCurrentHour(hour: number): boolean {
    return hour === moment().hour();
}

export function isTheSameHour(hour: number, date: moment.Moment): boolean {
    return hour === date.hour();
}

export function allHours(): number[] {
    return [...Array(24).keys()];
}

export function hoursGreaterThanCurrentHour(): number[] {
    return [...Array(24).keys()].filter((x) => x > moment().hour());
}

export function hoursGreaterThanAnHour(date: moment.Moment): number[] {
    return [...Array(24).keys()].filter((x) => x > date.hour());
}

export function hoursSmallerThanCurrentHour(date: moment.Moment): number[] {
    return [...Array(24).keys()].filter((x) => x < date.hour());
}

export function allMinutes(): number[] {
    return [...Array(60).keys()];
}

export function minutesGreaterThanCurrentMinute(): number[] {
    return [...Array(60).keys()].filter((x) => x > moment().minute());
}

export function minutesGreaterThanAMinute(date: moment.Moment): number[] {
    return [...Array(60).keys()].filter((x) => x > date.minute());
}

export function minutesSmallerThanCurrentMinute(date: moment.Moment): number[] {
    return [...Array(60).keys()].filter((x) => x < date.minute());
}

export function mergeDateTime(date: moment.Moment, time: moment.Moment): moment.Moment {
    return moment(`${date.format('yyyy-MM-DD')} ${time.format('HH:mm')}`, 'yyyy-MM-DD HH:mm');
}

export function isObjectEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
}


export function storedProjectNumber(): string {
    const url = document.location.href.match(/projects\/(.*?)\//);
    const projectNumber = url ? url[1] : '';
    return projectNumber;
}

export function storedOrderNumber(): string {
    const url = document.location.href.match(/orders\/(.*?)\//);
    const orderNumber = url ? url[1] : '';
    return orderNumber;
}