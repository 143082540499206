import { Direction, OffsetPagination } from '@nexploretechnology/concreting-core-client/pagination';
import { ConcreteTypeDetailsSupplierUI, IOrderElementForTableSupplierUI, LoadOrdersByBatcherSupplierUIResponse, TOrderStatusSupplierUI } from '@nexploretechnology/concreting-core-client/supplier-ui-client/supplier.ui.dto';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useContext, useEffect, useRef, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { SupplierContext } from '../../../../providers/SupplierProvider';
import CancelOrderDetailsModal from '../CancelOrderDetailsModal/CancelOrderModalDetails';
import ConcreteTypeModal from '../ConcreteTypeModal/ConcreteTypeModal';
import { orderMonitoringColumns } from './OrderMonitoringTable.columns';

interface ProjectsTableProps {
    companyId: string;
    flowStates: string[];
    setCount: React.Dispatch<React.SetStateAction<number>>;
}

const OrderMonitoringTable = ({ companyId, flowStates, setCount }: ProjectsTableProps) => {
    const pageSize: number = 10;
    const [orders, setOrders] = useState([] as IOrderElementForTableSupplierUI[]);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [selectedConcreteType, setSelectedConcreteType] = useState({} as ConcreteTypeDetailsSupplierUI);
    const [showConcreteDetailsModal, setShowConcreteDetailsModal] = useState<boolean>(false);
    const navigate: NavigateFunction = useNavigate();
    const [cancelOrderDetailsModalVisible, setCancelOrderDetailsModalVisible] = useState(false);
    const [statusToShowCancelOrderDetails, setStatusToShowCancelOrderDetails] = useState({} as TOrderStatusSupplierUI);
    const [orderToShow, setOrderToShow] = useState({} as string);
    const [projectId, setProjectId] = useState({} as string);
    const offsePagination = useRef({skip: 0, limit: pageSize, sorting: []} as OffsetPagination<IOrderElementForTableSupplierUI>);

    const pagination = {
        position: ['bottomRight'],
        pageSize: pageSize,
        total: totalOrders
    } as TablePaginationConfig;
    let sorting: Partial<Record<keyof IOrderElementForTableSupplierUI, Direction>>;
    const { supplierUIClient } = useContext(SupplierContext);
    async function onChange(
        paginationOnChange: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<IOrderElementForTableSupplierUI> | SorterResult<IOrderElementForTableSupplierUI>[],
        extra: TableCurrentDataSource<IOrderElementForTableSupplierUI>
    ) {
        sorter = sorter as SorterResult<IOrderElementForTableSupplierUI>;

        if (sorter.columnKey && sorter.order) {
            const sortDirection: string = { ascend: 'asc', descend: 'desc' }[sorter.order];
            const sortingValue: string = `{"${sorter.columnKey}": "${sortDirection || 'DESC'}"}`;
            sorting = JSON.parse(sortingValue);
        }

        const queryParams: OffsetPagination<IOrderElementForTableSupplierUI> = {
            skip:
                paginationOnChange.current! === 0
                    ? 0
                    : (paginationOnChange.current! - 1) * paginationOnChange.pageSize!,
            limit: paginationOnChange?.pageSize!,
            sorting: sorting ? [sorting] : []
        };

        getOrders(queryParams);
    }

    const getOrders = async (queryParams?: OffsetPagination<IOrderElementForTableSupplierUI>) => {

        const paginationParam: OffsetPagination<IOrderElementForTableSupplierUI> = {
            skip: queryParams ? queryParams.skip : 0,
            limit: queryParams ? queryParams.limit : pageSize,
            sorting: queryParams && queryParams.sorting ? queryParams.sorting : []
        };

        offsePagination.current = paginationParam;
        setTableLoading(true);
        supplierUIClient
            .loadOrdersByBatcher(companyId, flowStates, paginationParam)
            .then((response) => {
                if (response.isSuccess()) {
                    const { orders, totalCount }: LoadOrdersByBatcherSupplierUIResponse = response.getEntity();
                    setCount(totalCount);
                    setOrders(orders);
                    setTotalOrders(response.getEntity().totalCount);
                    setTableLoading(false);
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    };

    useEffect(() => {
        getOrders(offsePagination.current);
        // interval to load at 20000 milliseconds
        const interval = setInterval(() => {
            getOrders(offsePagination.current);
        }, 40000);

        return () => clearInterval(interval);
    }, [companyId]);

    const onEnterOrder = (projectId: string, orderId: string, status: TOrderStatusSupplierUI): void => {

        if (status === 'UPDATE REQUESTED') {
            navigate(`/company/${companyId}/project/${projectId}/order/${orderId}/comparison`);
        }
        else {
            navigate(`/company/${companyId}/project/${projectId}/order/${orderId}/details`);
        }
    };

    const onShowConcreteDetails = (concreteType: ConcreteTypeDetailsSupplierUI): void => {
        setSelectedConcreteType(concreteType);
        setShowConcreteDetailsModal(true);
    };

    function showCancelOrderDetailsModal(projectId: string, orderId: string, status: TOrderStatusSupplierUI): void {
        setProjectId(projectId);
        setOrderToShow(orderId);
        setStatusToShowCancelOrderDetails(status);
        setCancelOrderDetailsModalVisible(true);
    }

    function hideCancelOrderDetailsModal(): void {
        setCancelOrderDetailsModalVisible(false);
    }

    return (
        <>
            <Table
                data-testid="order-table"
                rowClassName="orderRow"
                rowKey={'orderId'}
                columns={orderMonitoringColumns({
                    onShowConcreteDetails: onShowConcreteDetails,
                    onEnterOrder: onEnterOrder,
                    showCancelOrderDetailsModal: showCancelOrderDetailsModal,
                })}
                dataSource={orders}
                loading={tableLoading}
                onChange={onChange}
                pagination={pagination}
            />

            <ConcreteTypeModal concreteTypeDetails={selectedConcreteType} visible={showConcreteDetailsModal} setVisible={setShowConcreteDetailsModal} />

            <CancelOrderDetailsModal
                orderStatus={statusToShowCancelOrderDetails}
                projectId = { projectId }
                orderId={orderToShow}
                hideModal={hideCancelOrderDetailsModal}
                showModal={cancelOrderDetailsModalVisible}
            />
        </>
    );
};

export default OrderMonitoringTable;
