import { t } from '@lingui/macro';
import { TimePicker as AntdTimePicker, TimePickerProps } from 'antd';
import useProjectConstantsState from 'src/app-react/hooks/useProjectConstantsState';


type Props = TimePickerProps & {projectId?: string}

function TimePicker({projectId, ...props}: Props) {
    const { formats } = useProjectConstantsState(projectId);

    return (
        <AntdTimePicker style={{ width: '100%' }} placeholder={t`Enter Time`} format={formats.timeForm} {...props} />
    );
}

export default TimePicker;
