import { notification } from 'antd';

export interface ISuccessNotification {
    message: string;
    description: string;
}

const InfoNotification = (props: ISuccessNotification) => {
    const { message, description } = props;

    return notification.info({
        message,
        description
    });
};

export default InfoNotification;
