import { IMarketUnits } from "../..";

const UNITS: IMarketUnits = {
    volume: 'm³',
    water: 'l',
    density: 'kg/m³',
    temperature: '°C',
    compressiveStrength: 'MPa',
    slump: 'mm',
    maxAggregateSize: 'mm'
};

export default UNITS;
