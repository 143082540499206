import { useMemo } from "react";
import { getMarketIdByProject } from "../services/user.service";
import { IMarketData } from "../constants/marketsData";
import getProjectConstants from "../services/projectService";
import { MARKETS } from "../constants/markets";

function useProjectConstantsState(projectId?: string, supplierId?: string) {
    const marketId = useMemo(() => projectId ? getMarketIdByProject(projectId) : MARKETS.DE, [projectId]);

    return useMemo<IMarketData['constants']>(
        () => getProjectConstants({ marketId, supplierId }),
        [marketId, supplierId]
    );
}

export default useProjectConstantsState;

