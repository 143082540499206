import React, { useEffect } from 'react';
import { useLingui } from '@lingui/react';

const FeedbackButton = () => {
    const { i18n } = useLingui();

    /*
    in the official example you need include 
    1) a script in your html file 
    2) and add a trigger to show the feedback modal
    */
    useEffect(() => {
        const script = document.createElement('script');

        /*
        1) a script in your html file 
        "custom trigger style" was used in Jira to create the scripts contained in the following links
        */
        const DE: string =
            'https://nexplore.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-9zew5j/b/6/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=de-DE&collectorId=75564215';
        const EN: string =
            'https://nexplore.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-9zew5j/b/6/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=951fe776';

        script.src = i18n.locale === 'de-DE' ? DE : EN;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [i18n]);

    useEffect(() => {
        const script: HTMLScriptElement = document.createElement('script');

        /*
        2) and add a trigger to show the feedback modal
        */
        script.innerHTML = `            
            window.ATL_JQ_PAGE_PROPS =  {
                "triggerFunction": function(showCollectorDialog) {
                    //Requries that jQuery is available! 
                    jQuery("#feedback-button").click(function(e) {
                        e.preventDefault();
                        showCollectorDialog();
                    });
                }
            };        
            `;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <button id="feedback-button" className="feedback-button">
                Feedback
            </button>
        </>
    );
};

export default FeedbackButton;
