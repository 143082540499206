import { notification } from 'antd';

export interface ISuccessNotification {
    message: string;
    description: string;
}

const SuccessNotification = (props: ISuccessNotification) => {
    const { message, description } = props;

    return notification.success({
        message,
        description
    });
};

export default SuccessNotification;
