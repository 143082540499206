// Import of the ant styles should be load always at first place
import './app-react/components/FeedbackButton/FeedbackButton.css';
import './styles/styles.less';

import ReactDOM from 'react-dom';
import App from './app-react/app';
import { loadAuth } from './launch';



loadAuth().then(() => {
    ReactDOM.render(
        <App/>,
        document.getElementById('root')
    );
});
