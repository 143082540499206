import { FileTextOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { ConcreteTypeDetailsSupplierUI, IOrderElementForTableSupplierUI, IQuantity, TOrderStatusSupplierUI } from '@nexploretechnology/concreting-core-client/supplier-ui-client/supplier.ui.dto';
import { Button, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import { dateTimeFormatter } from 'src/app-react/utils/formatters';
type FixedType = boolean | 'right' | 'left';

export interface OrderManagementTableColumnsProps {
    onShowConcreteDetails: (concreteType: ConcreteTypeDetailsSupplierUI) => void;
    onEnterOrder: (projectId: string, orderId: string, status: TOrderStatusSupplierUI) => void;
    showCancelOrderDetailsModal: (projectId: string, orderId: string, status: TOrderStatusSupplierUI) => void;
}

export const orderMonitoringColumns: (props: OrderManagementTableColumnsProps) => ColumnsType<IOrderElementForTableSupplierUI> = (
    props: OrderManagementTableColumnsProps
) => {
    const { onShowConcreteDetails, onEnterOrder, showCancelOrderDetailsModal } = props;

    return [
        {
            title: <Trans>Delivery date/time</Trans>,
            dataIndex: 'deliveryDate',
            key: 'deliveryTime',
            sorter: true,
            render: (deliveryDate: Date) => <>{`${dateTimeFormatter(deliveryDate)}`}</>
        },

        {
            title: <Trans>Status</Trans>,
            key: 'orderStatus',
            dataIndex: 'status',
            sorter: true,
            hidden: false,
            render: ( status: TOrderStatusSupplierUI, record: IOrderElementForTableSupplierUI) => {
                return (
                    <p>
                        <Tag data-testid="status-tag" color={statusColor(status)}>
                            {statusText(status)}
                        </Tag>
                        {status === 'CANCELLATION REQUESTED' || status === 'ORDER CANCELLED'
                        ?
                            <InfoCircleOutlined
                                type="primary"
                                onClick={() => showCancelOrderDetailsModal(record.projectId, record.orderId, status)}
                            />
                        :
                            <></>
                        }
                    </p>
                )
            }
        },

        {
            title: <Trans>Project</Trans>,
            dataIndex: 'projectName',
            key: 'project',
            sorter: false
        },

        {
            title: <Trans>Concrete Type</Trans>,
            dataIndex: 'concreteType',
            key: 'orderedConcreteTypeNumber',
            sorter: true,
            render: (concreteType: ConcreteTypeDetailsSupplierUI ) => (
                <RawLinkButton data-testid="orderNumberLink" onClick={() => onShowConcreteDetails(concreteType)}>
                    {concreteType.concreteType}
                </RawLinkButton>
            )
        },

        {
            title: <Trans>Quantity</Trans>,
            dataIndex: 'quantity',
            key: 'concreteVolume',
            sorter: true,
            render: (quantity: IQuantity) => <>{`${quantity.amount} ${quantity.unit}`}</>
        },

        {
            title: <Trans>Additional quantity required</Trans>,
            dataIndex: 'additionalQuantity',
            key: 'additionalQuantityRequired',
            sorter: true,
            render: (additionalQuantity: boolean) => additionalQuantity ? <Trans>Yes</Trans> : <Trans>No</Trans>
        },

        {
            title: <Trans>Additional requirements</Trans>,
            dataIndex: 'additionalRequirements',
            key: 'additionalRequirements',
            sorter: true,
        },

        {
            title: <Trans>Last changed</Trans>,
            dataIndex: 'lastChange',
            key: 'lastModifiedOn',
            sorter: true,
            render: (lastChange: Date) => <>{`${dateTimeFormatter(lastChange)}`}</>
        },

        {
            title: <Trans>Actions</Trans>,
            dataIndex: 'actionDeliveryNote',
            fixed: 'right' as FixedType,
            hidden: false,
            render: (_: string, record: IOrderElementForTableSupplierUI) => (
                <Button
                    key="actionDeliveryNote"
                    data-testid={'editButton'}
                    type="link"
                    size="small"
                    onClick={() => onEnterOrder(record.projectId, record.orderId, record.status)}>
                        {/* className={styles.buttonIcon} */}
                    <FileTextOutlined /> <Trans>Enter</Trans>
                </Button>
            )
        }
    ];
};


function statusText(status: TOrderStatusSupplierUI): JSX.Element {
    switch (status) {
        case 'ORDER CONFIRMED':
            return <Trans>Order confirmed</Trans>;
        case 'ORDER CANCELLED':
            return <Trans>Order cancelled</Trans>;
        case 'UPDATE REQUESTED':
            return <Trans>Update requested</Trans>;
        case 'CANCELLATION REQUESTED':
            return <Trans>Cancellation requested</Trans>;
        case 'ORDER INQUIRED':
            return <Trans>Order inquired</Trans>;
        case 'ORDER REJECTED FINAL':
        case 'ORDER REJECTED':
            return <Trans>Order rejected</Trans>;
        case 'ORDER COMPLETED':
            return <Trans>Completed</Trans>;
        case 'ORDER EXPIRED':
            return <Trans>Expired</Trans>;
    }
}

function statusColor(status: TOrderStatusSupplierUI): string {
    let color: string;
    switch (status) {
        case 'ORDER CONFIRMED':
            color = 'green';
            break;
        case 'CANCELLATION REQUESTED':
        case 'ORDER COMPLETED':
            color = 'orange';
            break;
        case 'ORDER REJECTED FINAL':
        case 'ORDER REJECTED':
        case 'ORDER CANCELLED':
            color = 'red';
            break;
        default:
            color = 'blue'; // grey is not supported by ant design
    }
    return color;
}
