import { t, Trans } from "@lingui/macro";
import { ConcreteTypeDetailsSupplierUI } from "@nexploretechnology/concreting-core-client/supplier-ui-client/supplier.ui.dto";
import { Button, Col, Modal, Row } from "antd";
import { numberFormat } from "src/app-react/utils/formatters";
import styles from './ConcreteTypeModal.module.css';

interface ConcreteTypeProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    concreteTypeDetails: ConcreteTypeDetailsSupplierUI;
}


const ConcreteTypeModal = ({visible, setVisible, concreteTypeDetails}: ConcreteTypeProps) => {

    function onCancel() {
        setVisible(false);
    };

    function mapStrengthDevelopmentClass(storedValue: string): string {
        switch (storedValue) {
            case 'fast':
                return t`Fast`;
            case 'intermediate':
                return t`Intermediate`;
            case 'slow':
                return t`Slow`;
            case 'very slow':
                return t`Very slow`;
            default:
                return '';
        }
    }

    function optionMethodConcrete(option: string): string {
        switch (option) {
            case 'prestressedConcrete / Cl 0,20':
                return t`Prestressed concrete / Cl 0,20`;
            case 'reinforcedConcrete / Cl 0,40':
                return t`Reinforced concrete  / Cl 0,40`;
            case 'nonReinforcedConcrete / Cl 1,00':
                return t`Non-reinforced concrete / Cl 1,00`;
            case 'prestressedConcrete':
                return t`Prestressed concrete`;
            case 'reinforcedConcrete':
                return t`Reinforced concrete`;
            case 'nonReinforcedConcrete':
                return t`Non-reinforced concrete`;
        }
        return '';
    }

    return (
        <Modal
            visible={visible}
            forceRender
            onCancel={onCancel}
            title={t`Concrete Details`}
            // title={marketId === marketDE ? t`Concrete Details` : t`Mix design details`}
            width={750}
            data-testid="modal"
            footer={[
                <Button
                    data-testid="closeButton"
                    key="send"
                    type="primary"
                    onClick={onCancel}>
                    {t`Close`}
                </Button>
            ]}>
            <Row gutter={[48, 16]}>
                {/* {marketId === marketDE ? firstBlockRenderMarketIdDE() : firstBlockRenderMarketIdUS()} */}

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Concrete Type</Trans>
                    </label>
                </Col>
                <Col span={12}>{concreteTypeDetails.concreteType || ''}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Compressive strength class</Trans>
                    </label>
                </Col>
                <Col span={12}>{concreteTypeDetails.strenghtClass || ''}</Col>

                <Col span={12}>
                    <label  className={styles.modalLabel}>
                        <Trans>Exposure Class</Trans>
                    </label>
                </Col>
                <Col span={12}>{concreteTypeDetails.exposureClasses}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Moisture Class</Trans>
                    </label>
                </Col>
                <Col span={12}>{concreteTypeDetails.moistureClass || ''}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Consistency Class</Trans>
                    </label>
                </Col>
                <Col span={12}>{concreteTypeDetails.consistencyClass || ''}</Col>


                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Maximum Aggregate size</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {concreteTypeDetails.maxAggregationSize
                        ? numberFormat(parseInt(concreteTypeDetails.maxAggregationSize))
                        : ''}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Strength development class</Trans>
                    </label>
                </Col>
                <Col span={12}>{mapStrengthDevelopmentClass(concreteTypeDetails.strengthDevelopment)}</Col>


                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Cements</Trans>
                    </label>
                </Col>
                <Col span={12}>{concreteTypeDetails.cement || ''}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Supplementary cementious materials</Trans>
                    </label>
                </Col>
                <Col span={12}>{concreteTypeDetails.additives || ''}</Col>


                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>AdMixtures</Trans>
                    </label>
                </Col>
                <Col span={12}>{concreteTypeDetails.admixtures || ''}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Density Class</Trans>
                    </label>
                </Col>
                <Col span={12}>{concreteTypeDetails.densityClass || ''}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Method of concrete use</Trans>
                    </label>
                </Col>
                <Col span={12}>{optionMethodConcrete(concreteTypeDetails.methodOfConcrete) || ''}</Col>


                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Testing Age (Days)</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {concreteTypeDetails.testingAge ? concreteTypeDetails.testingAge : ''}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Water/Cement Ratio</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {concreteTypeDetails.waterCementRatio ? concreteTypeDetails.waterCementRatio : ''}
                </Col>
            </Row>
        </Modal>
    );

}

export default ConcreteTypeModal;