import { DatePicker as AntdDatePicker, DatePickerProps } from 'antd';
import useProjectConstantsState from 'src/app-react/hooks/useProjectConstantsState';

type Props = DatePickerProps & {projectId?: string}

function DatePicker({projectId, ...props}: Props) {
    const { formats } = useProjectConstantsState(projectId);

    return <AntdDatePicker style={{ width: '100%' }} format={formats.dateForm} {...props} />;
}

export default DatePicker;
