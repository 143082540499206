
import { ProjectManagementClient } from "@nexploretechnology/concreting-core-client/project.management-client/project.management.client";
import { Project } from "@nexploretechnology/concreting-core-client/project.management-client/project.management.dto";
import { SimpleClientResponse } from "@nexploretechnology/concreting-core-client/simple.client.response";
import { MARKET_VALUES } from '../constants/markets';
import { MARKETS_DATA } from '../constants/marketsData';
import { getAuthService } from "./authService";

let project: Project | undefined = undefined;

export async function getActiveProjectName(projectId: string): Promise<string> {

    if (!project || project.id !== projectId) {

        const token: string = '';
        const companyId: string = getAuthService().getActiveCompanyId();
        const projectManagementClient: ProjectManagementClient = new ProjectManagementClient(getAuthService().getAxiosInstance());

        const response: SimpleClientResponse<Project> = await projectManagementClient.getProject(companyId, projectId, token)

        if (response.isSuccess()) {
            project = response.getEntity();
            return project.name;
        } else {
            project = undefined;
        }
    }
    return project?.name ? project.name : '';
}


export default function getProjectConstants({ marketId, supplierId }: { marketId: string; supplierId?: string }) {
    return {
        units: {},
        formats: {},
        ...MARKETS_DATA[marketId as MARKET_VALUES]?.constants,
    };
}
