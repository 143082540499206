import { IMarketUnits } from "../..";

const UNITS: IMarketUnits = {
    volume: 'm³',
    density: 'kg/m³',
    temperature: '°C',
    slump: 'mm'
};

export default UNITS;
