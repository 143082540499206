import {
    BellOutlined,
    CarryOutOutlined, GlobalOutlined,
    LogoutOutlined, UserOutlined
} from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Button, Dropdown, Layout, Menu, MenuProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuthService } from 'src/app-react/services/authService';
import logoNexplore from '../../../assets/images/nexplore-logo.svg';
import FeedbackButton from '../FeedbackButton/FeedbackButton';
import LanguageModal from '../LanguageModal/LanguageModal';
import styles from './AppMenu.module.css';

const { Header, Sider, Content } = Layout;
type MenuChildren = { children: React.ReactNode };

const AppMenu = ({ children }: MenuChildren) => {
    const [collapsed, setCollased] = useState(true);
    const [languageModalVisible, setLanguageModalVisible] = useState(false);
    const [selectedMenuItem] = useState('orders');

    const onCollapse = (collapse: boolean) => {
        setCollased(collapse);
    };

    const showLanguageModal = (): void => {
        setLanguageModalVisible(true);
    };

    const logout = (): void => {
        getAuthService().logout();
    };

    const renderTopMenu = (): JSX.Element => {
        const topMenuItems: MenuProps['items'] = [
            {
                key: 'language',
                label: (
                    <Button data-testid="languageMenuBtn" type="text" onClick={() => showLanguageModal()}>
                        {' '}
                        <GlobalOutlined /> <Trans>Language</Trans>{' '}
                    </Button>
                )
            },

            {
                key: 'logout',
                label: (
                    <Button data-testid="logoutMenuBtn" type="text" onClick={() => logout()}>
                        {' '}
                        <LogoutOutlined /> <Trans>Logout</Trans>{' '}
                    </Button>
                )
            }
        ];

        return <Menu items={topMenuItems} />;
    };

    const renderSideMenuItems = (): ItemType[] => {

        let sideMenuItems: MenuProps['items'] = [
            {
                key: 'orders',
                label: (
                    <Link data-testid="ordersMenuBtn" to={`/order-monitoring`}>
                        <Trans>Order monitoring</Trans>
                    </Link>
                ),
                icon: <CarryOutOutlined />
            },
        ];
        
        return sideMenuItems;
    };


    return (
        <>
            <Layout className={styles.menu}>
                <Header className={styles.menuHeader}>
                    <div className={styles.menuTitle}>
                        <div className={styles.menuLogo}>
                            <img alt="logo" src={logoNexplore} />
                        </div>
                        <div className={styles.menuName}>
                            <Link to="/projects">
                                <Trans>Concrete</Trans>
                            </Link>
                        </div>
                    </div>

                    <div className={styles.menuTitle}>
                        <FeedbackButton></FeedbackButton>

                        <Dropdown
                            trigger={['click']}
                            overlay={<></>}
                            placement="bottom"
                            className={styles.menuRightIcons}>
                            <Button
                                hidden={true}
                                icon={
                                    <div className={styles.menuDropdown}>
                                        <BellOutlined style={{ fontSize: '1.4rem' }} />
                                        <p>
                                            <Trans>Updates</Trans>
                                        </p>
                                    </div>
                                }></Button>
                        </Dropdown>

                        <Dropdown
                            trigger={['click']}
                            overlay={renderTopMenu}
                            placement="bottom"
                            className={styles.menuRightIcons}>
                            <Button
                                icon={
                                    <div className={styles.menuDropdown}>
                                        <UserOutlined style={{ fontSize: '1.4rem' }} />
                                        <p>
                                            <Trans>Profile</Trans>
                                        </p>
                                    </div>
                                }></Button>
                        </Dropdown>
                    </div>
                </Header>
                <Layout>
                    <Sider
                        className={styles.menuSidebar}
                        width={240}
                        collapsible
                        collapsed={collapsed}
                        onCollapse={onCollapse}>
                        <Menu
                            theme="dark"
                            className={styles.menuSidebar}
                            mode="inline"
                            items={renderSideMenuItems()}
                            selectedKeys={[selectedMenuItem]}
                        />
                    </Sider>
                    <Content className={styles.menuContent}>{children}</Content>
                </Layout>
            </Layout>

            <LanguageModal visible={languageModalVisible} setVisible={setLanguageModalVisible} />
        </>
    );
};

export default AppMenu;
