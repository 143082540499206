export interface IKeycloakConfig {
    readonly url: string;
    readonly realm: string;
    readonly clientId: string;
}

export interface IConfigService {
    readonly host: string;
    readonly coreHost: string;
    readonly concreteSupplierUi: string;
    readonly appClientUrl: string;
    readonly adminClientUrl: string;
    readonly mbkHost: string;
    readonly keycloakConfig: IKeycloakConfig;
}

let configService: IConfigService | null = null;

export function getConfigService(): IConfigService {
    if (!configService) {
        throw new Error('Not config service defined yet');
    }

    return configService;
}

export function setConfigService(config: IConfigService): void {
    configService = config;
}
