/* tslint:disable */
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import React from 'react';

// TODO NB-3733: remove any when migrate from angular to react: https://nexplore.atlassian.net/browse/NB-3733
const dateTimeFormat: any = { dateStyle: 'medium', timeStyle: 'medium' };
const timeFormat: any = { timeStyle: 'short' };
const dateFormat: any = { dateStyle: 'medium' };

export function dateTimeFormatter(date: Date | string | null | undefined, format?: any): string | null {
    const useFormat = format ? format : dateTimeFormat;
    return date ? i18n.date(date, useFormat) : null;
}

export function dateFormatter(date: Date | string | null | undefined, format?: any): string | null {
    const useFormat = format ? format : dateFormat;
    return date ? i18n.date(date, useFormat) : null;
}
export function timeFormatter(date: Date | string | null | undefined, format?: any): string | null {
    const useFormat = format ? format : timeFormat;
    return date ? i18n.date(date, useFormat) : null;
}

export function removeIdentifierFromFormFieldName(elementKey: string): string {
    return elementKey.slice(0, -2);
}

export const weatherMapper: { [key: string]: React.ReactElement<any, any> | undefined } = {
    sunny: <Trans>Sunny</Trans>,
    cloudy: <Trans>Cloudy</Trans>,
    rainy: <Trans>Rainy</Trans>,
    heavyRain: <Trans>Heavy Rain</Trans>,
    windy: <Trans>Windy</Trans>,
    snow: <Trans>Snow</Trans>,
    foggy: <Trans>Foggy</Trans>
};

export function quantityFormatter(amount: number, unit: string): string {
    return `${amount} ${mapUnit(unit)}`;
}

export function unitFormatter(unit: string): string {
    return mapUnit(unit);
}

export const concreteInformationCategoryMapper: { [key: string]: React.ReactElement<any, any> } = {
    concreteTypeNumber: <Trans>Concrete Type Number</Trans>,
    mixDesignNumber: <Trans>Mix design number</Trans>,
    comprehensiveStrengthClass: <Trans>Compressive Strength Class</Trans>,
    strengthDevelopment: <Trans>Strength Development</Trans>,
    exposureClass: <Trans>Exposure Class(es)</Trans>,
    methodOfConcrete: <Trans>Method of concrete / Chloride content</Trans>,
    consistencyClass: <Trans>Consistency Class</Trans>,
    waterCementRatio: <Trans>Water/cement ratio (eq)</Trans>,
    cementTypes: <Trans>Cement type and origin</Trans>,
    additivesFluid: <Trans>Admixtures</Trans>,
    additivesSolid: <Trans>Supplementary Cementitious Materials</Trans>,
    AggregateSize: <Trans>Maximum Aggregate size/grain size</Trans>,
    aggregateSize: <Trans>Maximum Aggregate size/grain size</Trans>,
    densityClass: <Trans>Density Class</Trans>,
    moistureClass: <Trans>Moisture Class</Trans>,
    FiverType: <Trans>Fiber type and quantity</Trans>,
    fiverType: <Trans>Fiber type and quantity</Trans>,
    SpecialProperties: <Trans>Special properties</Trans>,
    specialProperties: <Trans>Special properties</Trans>,
    slump: <Trans>Slump</Trans>,
    compressiveStrength: <Trans>Compressive strength</Trans>
};

export const commentActionMapper: { [key: string]: React.ReactElement<any, any> | undefined } = {
    reportArrival: <Trans>Arrival</Trans>,
    startUnloading: <Trans>Unloading start</Trans>,
    endUnloading: <Trans>Unloading end</Trans>,
    acceptAndSign: <Trans>Accepted and signed</Trans>,
    rejectConcreteLoad: <Trans>Concrete load rejection</Trans>
};

export function mapUnit(unit: string): string {
    switch (unit) {
        case 'inches':
            return 'in';
        case 'm3':
            return 'm³';
        case 'YD3':
        case 'yd3':
            return 'yd³';
        case 'mh3':
        case 'm3h':
            return 'm³/h';
        default:
            return unit;
    }
}

export function numberFormat(number: number | undefined): string | null {
    return number ? i18n.number(number) : null;
}

export function formatNumberValueToLanguage(value: string): string {
    return !isNaN(Number(value)) ? `${numberFormat(Number(value))}` : value;
}

export function parseDecimalByLocale(decimalString: string, localeString: string): number {
    if (localeString === 'de-DE') {
        return Number(decimalString.replace(',', '.'));
    }
    return Number(decimalString);
}

export function formatDecimalByLocale(value: string | number | undefined, localeString: string): string | undefined {
    if (typeof value === 'number') {
        return value.toLocaleString(localeString || 'en-GB');
    }

    if (typeof value === 'string') {
        if (localeString === 'de-DE') {
            return value.replace('.', ',');
        }
        return value;
    }

    return undefined;
}

export const cancelReasonCodeMapper: { [key: string]: React.ReactElement<any, any> | undefined } = {
    siteNotReady: <Trans>Site not ready</Trans>,
    permitsNotAvailable: <Trans>Permits not available</Trans>,
    weather: <Trans>Weather conditions</Trans>,
    changeInSchedule: <Trans>Change in schedule</Trans>,
    incorrectOrder: <Trans>Incorrect order</Trans>,
    cancelledBySupplier: <Trans>Cancelled by supplier</Trans>,
    other: <Trans>Other</Trans>
};
