import { I18n, i18n, MessageDescriptor } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ConfigProvider } from 'antd';
import antdDeDE from 'antd/lib/locale/de_DE';
import antdEnUK from 'antd/lib/locale/en_GB';
import antdEnUS from 'antd/lib/locale/en_US';
import antdEsES from 'antd/lib/locale/es_ES';
import { de, en, es } from 'make-plural/plurals';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import React, { useEffect } from 'react';
import { messages as deTranslations } from '../locales/de-DE/translations';
import { messages as ukTranslations } from '../locales/en-UK/translations';
import { messages as enTranslations } from '../locales/en-US/translations';
import { messages as esTranslations } from '../locales/es-ES/translations';

export interface LanguageProps {
    changeLanguage: (language: Language) => void;
    getLanguage: () => string;
}

export type Language = 'en-UK' | 'en-US' | 'de-DE';

const LanguageContext = React.createContext({} as LanguageProps);
type ProviderChildren = { children: React.ReactNode };

i18n.loadLocaleData({
    'en-UK': { plurals: en },
    'es-ES': { plurals: es },
    'de-DE': { plurals: de },
    'en-US': { plurals: en }
});

i18n.load({
    'en-UK': ukTranslations,
    'es-ES': esTranslations,
    'de-DE': deTranslations,
    'en-US': enTranslations
});

function getAntdLocale(locale: string) {
    switch (locale) {
        case 'de-DE':
            return antdDeDE;
        case 'es-ES':
            return antdEsES;
        case 'en-US':
            return antdEnUS;
        default:
            return antdEnUK;
    }
}

function getMomentLocale(locale: string) {
    switch (locale) {
        case 'de-DE':
            return 'de';
        case 'es-ES':
            return 'es';
        case 'en-US':
            return 'en';
        default:
            return 'en-gb';
    }
}

const searchParams = new URLSearchParams(window.location.search);
if (searchParams.has('phrase')) {
    initializePhraseAppEditor(
        {
            fullReparse: true,
            autoLowercase: false,
            prefix: '{{__',
            suffix: '__}}',
            projectId: '0dfcf2c9043e857f893ccfc34fdac070'
        },
        i18n
    );
}
async function changeLanguage(language: Language) {
    i18n.activate(language);
    localStorage.setItem('NexploreSupplier.preferredLanguage', language);
}

function getLanguage(): string {
    return localStorage.getItem('NexploreSupplier.preferredLanguage');
}

const LanguageProvider = ({ children }: ProviderChildren) => {
    const storedLanguage: Language | null = getLanguage() as Language | null;

    useEffect(() => {
        changeLanguage(storedLanguage || 'en-UK');
    });

    return (
        <I18nProvider i18n={i18n} forceRenderOnLocaleChange={true}>
            <AppConfigProvider>{children}</AppConfigProvider>
        </I18nProvider>
    );
};

function AppConfigProvider({ children }: ProviderChildren) {
    const value: LanguageProps = {
        changeLanguage: changeLanguage,
        getLanguage: getLanguage
    };

    moment.locale(getMomentLocale(i18n.locale));
    return (
        <ConfigProvider locale={getAntdLocale(i18n.locale)}>
            <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
        </ConfigProvider>
    );
}

export { LanguageProvider, LanguageContext };

function initializePhraseAppEditor(config: any, i18nInstance: I18n): void {
    i18nInstance._ = (id: string | MessageDescriptor): string => {
        let message: string;
        if (typeof id === 'string') {
            message = id;
        } else {
            message = id.id || 'translation id not found';
        }

        message = message.replace('<', '[[[[[[html_open]]]]]]').replace('>', '[[[[[[html_close]]]]]]');
        message = config.prefix + 'phrase_' + message + config.suffix;

        return message;
    };

    (window as any).PHRASEAPP_CONFIG = config;

    const phraseapp = document.createElement('script');
    phraseapp.type = 'text/javascript';
    phraseapp.async = true;
    phraseapp.src = 'https://phraseapp.com/assets/in-context-editor/2.0/app.js?' + new Date().getTime();
    var s = document.getElementsByTagName('script')[0];
    if (s && s.parentNode) {
        s.parentNode.insertBefore(phraseapp, s);
    } else {
        document.insertBefore(phraseapp, null);
    }
}
