import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';
import AppMenu from './components/AppMenu/AppMenu';
import { LanguageProvider } from './providers/LanguageProvider';

const App = () => {
    return (
        <BrowserRouter>
            <LanguageProvider>
                <AppMenu>
                    <AppRouter />
                </AppMenu>
            </LanguageProvider>
        </BrowserRouter>
    );
};
export default App;
