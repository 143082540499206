import { t, Trans } from '@lingui/macro';
import { Badge, PageHeader, Row, Select, Space, Tabs } from 'antd';
import { useContext, useState } from 'react';
import { getUserCompanies } from 'src/app-react/services/user.service';
import { SupplierContext } from '../../providers/SupplierProvider';
import OrderMonitoringTable from './components/OrderMonitoringTable/OrderMonitoringTable';

const { Option } = Select;
const { TabPane } = Tabs;

const OrderMonitoringPage = () => {
    const [companies] = useState(getUserCompanies());
    const { companyId, setCompanyId } = useContext(SupplierContext);

    const [activeTab, setActiveTab] = useState<string>('nonElapsedTable');
    const [activeCount, setActiveCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [cancelledCount, setCancelledCount] = useState(0);

    function changeCompany(selectedCompany: string) {
        setCompanyId(selectedCompany);
    }

    function getCurrentCompany(): string {
        return companies.filter(company => company.companyId === companyId)[0].companyName;
    }

    return (
        <>
            <PageHeader style={{paddingLeft: 0}}  title={t`Order Monitoring`} backIcon={false} />
            <Row>
                <Space>
                    <Trans>Selected company</Trans>
                    <Select data-testid="order-select" defaultValue={getCurrentCompany()} onChange={changeCompany} style={{ width: 250 }} >
                        {
                            companies.map(company => {
                                return (
                                    <Option
                                        key={company.companyId}
                                        value={company.companyId}
                                    >{company.companyName}</Option>
                                );
                            })
                        }
                    </Select>
                </Space>
            </Row>
            <br />

            <>

                <Tabs defaultActiveKey={activeTab} onChange={setActiveTab}>
                    <TabPane
                        forceRender={true}
                        key="nonElapsedTable"
                        tab={
                            <>
                                <Trans>Active</Trans>
                                <Badge count={activeCount} />
                            </>
                        }>
                        <OrderMonitoringTable
                            companyId={companyId}
                            flowStates={['init', 'inquiryDraft', 'inquiryRejected', 'inquiryReview', 'confirmed', 'changeRequest', 'changeRequestRejected', 'cancellationRequested']}
                            setCount={setActiveCount}
                        ></OrderMonitoringTable>

                    </TabPane>
                    <TabPane
                        forceRender={true}
                        key="elapsedTable"
                        tab={
                            <>
                                <Trans>Completed</Trans>
                                <Badge count={completedCount} />
                            </>
                        }>
                        <OrderMonitoringTable
                            companyId={companyId}
                            flowStates={['completed', 'expired', 'rejectedFinal']}
                            setCount={setCompletedCount}
                        ></OrderMonitoringTable>

                    </TabPane>
                    <TabPane
                        forceRender={true}
                        key="Cancelled"
                        tab={
                            <>
                                <Trans>Cancelled</Trans> <Badge count={cancelledCount} />
                            </>
                        }>
                        <OrderMonitoringTable
                            companyId={companyId}
                            setCount={setCancelledCount}
                            flowStates={['cancelled']}
                        ></OrderMonitoringTable>
                    </TabPane>
                </Tabs>

            </>
        </>
    );
};

export default OrderMonitoringPage;
