import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { ConcreteForSelectInputSupplierUI, InputOrderToCancel, OrderSupplierUI, ProjectInfoSupplierUI } from '@nexploretechnology/concreting-core-client/supplier-ui-client/supplier.ui.dto';
import { Button, Modal, PageHeader } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import { getAuthService } from 'src/app-react/services/authService';
import { isObjectEmpty } from 'src/app-react/utils/lib';
import { SupplierContext } from '../../providers/SupplierProvider';
import DetailsForm from './components/DetailsForm/DetailsForm';
import ProjectInfoTable from './components/ProjectInfoTable/ProjectInfoTable';

interface OrderDetailsPageProps {
    companyId: string;
    projectId: string;
    orderId: string;
}

const OrderDetailsPage = ({ companyId, projectId, orderId }: OrderDetailsPageProps) => {
    const { supplierUIClient } = useContext(SupplierContext);
    const { supplierUICoreClient } = useContext(SupplierContext);
    const [projectInfo, setProjectInfo] = useState({} as ProjectInfoSupplierUI);
    const [concreteTypes, setConcreteTypes] = useState([] as ConcreteForSelectInputSupplierUI[]);
    const [order, setOrder] = useState({} as OrderSupplierUI);
    const navigate: NavigateFunction = useNavigate();
    const { confirm } = Modal;

    function getData() {
        getProjectInfo();
        getConcreteTypes();
        getOrder();
    }

    function returnToMainPage() {
        navigate(`/order-monitoring`);
    }

    async function getProjectInfo() {
        supplierUIClient
            .loadProjectInformation(companyId, projectId)
            .then((response) => {
                if (response.isSuccess()) {
                    setProjectInfo(response.getEntity());
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    }

    async function getConcreteTypes() {
        supplierUICoreClient
            .loadConcreteTypes(companyId, projectId)
            .then((response) => {
                if (response.isSuccess()) {
                    setConcreteTypes(response.getEntity());
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    }

    async function getOrder() {
        supplierUIClient
            .getOrder(companyId, projectId, orderId)
            .then((response) => {
                if (response.isSuccess()) {
                    setOrder(response.getEntity());
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    }

    async function cancelOrder() {
        const inputCancel: InputOrderToCancel = {
            cancelledReasonCode: 'cancelledBySupplier',
            cancelledReasonComment: 'order cancelled by supplier',
            cancelledBy: getAuthService().user.userId
        };

        supplierUIClient
            .cancelOrder(companyId, projectId, orderId, inputCancel)
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({ message: t`Order has been successfully cancelled`, description: '' });
                    returnToMainPage();
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    }

    function displayCancelModal() {
        confirm({
            wrapClassName: 'cancel-modal',
            title: t`Cancel Order`,
            icon: <ExclamationCircleOutlined />,
            content: t`Your are about to cancel this order. This means it will not be longer available.`,
            okText: t`Confirm cancelation`,
            okType: 'primary',
            okButtonProps: { danger: true, className: 'cancel-modal-btn' },
            cancelText: 'Close',
            onOk() {
                cancelOrder();
            },
            onCancel() {}
        });
    }

    function displayCancelButton(status: string): JSX.Element[] {
        if (status !== 'ORDER COMPLETED' && status !== 'ORDER EXPIRED' && status !== 'ORDER CANCELLED') {
            return [
                <Button data-testid="cancel-btn" danger={true} type="primary" onClick={displayCancelModal}>
                    <DeleteOutlined /> <Trans>Cancel order</Trans>
                </Button>
            ];
        }

        return [];
    }

    useEffect(() => {
        getData();
        // interval to load at 20000 milliseconds
        const interval = setInterval(() => {
            getData();
        }, 20001);

        return () => clearInterval(interval);
    }, [companyId, projectId, orderId]);

    return isObjectEmpty(projectInfo) || isObjectEmpty(order) || isObjectEmpty(concreteTypes) ? null : (
        <>
            <PageHeader
                style={{ paddingLeft: 0, paddingRight: 0 }}
                onBack={returnToMainPage}
                title={order.orderId}
                extra={displayCancelButton(order.status)}
            />
            <ProjectInfoTable projectInfo={[projectInfo]} orderId={orderId}></ProjectInfoTable>
            <DetailsForm
                companyId={companyId}
                projectId={projectId}
                concreteTypes={concreteTypes}
                order={order}></DetailsForm>
        </>
    );
};

export default OrderDetailsPage;
