import { MARKETS, MARKET_VALUES } from "../markets";
import MARKET_DATA_AUS from "./marketDataAUS";
import MARKET_DATA_DE from "./marketDataDE";
import MARKET_DATA_US from "./marketDataUS";


export interface IMarketUnits {
    volume: string;
    water?: string;
    density: string;
    temperature: string;
    compressiveStrength?: string;
    slump?: string;
    maxAggregateSize?: string;
}

export interface IMarketFormats {
    dateTimeForm: string;
    dateForm: string;
    timeForm: string;
    dateTime: string;
    date: string;
    time: string;
}

export interface IMarketData {
    constants?: {
        units: IMarketUnits;
        formats: IMarketFormats;
    }
}

export const MARKETS_DATA: Record<MARKET_VALUES, IMarketData> = {
    [MARKETS.DE]: MARKET_DATA_DE,
    [MARKETS.US]: MARKET_DATA_US,
    [MARKETS.AUS]: MARKET_DATA_AUS
};
