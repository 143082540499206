import { t, Trans } from "@lingui/macro";
import { CancelledOrder } from "@nexploretechnology/concreting-core-client";
import { TOrderStatusSupplierUI } from "@nexploretechnology/concreting-core-client/supplier-ui-client/supplier.ui.dto";
import { Button, Col, Modal, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import ErrorNotification from "src/app-react/components/Notification/ErrorNotification";
import { cancelReasonCodeMapper, dateFormatter, timeFormatter } from "src/app-react/utils/formatters";
import { SupplierContext } from "../../../../providers/SupplierProvider";
import styles from './CancelOrderModalDetails.module.css';

interface CancelOrderDetailsModalProps {
    orderStatus: TOrderStatusSupplierUI;
    projectId: string;
    orderId: string;
    hideModal: () => void;
    showModal: boolean;
}

const CancelOrderDetailsModal = (props: CancelOrderDetailsModalProps) => {

    const { orderStatus, projectId, orderId, hideModal, showModal } = props;
    const { companyId, supplierUIClient } = useContext(SupplierContext);
    const [ orderCancellationDetails, setOrderCancellationDetails] = useState<CancelledOrder>(
        {} as CancelledOrder
    );

    useEffect(() => {
        if (showModal) {
            supplierUIClient.loadCancellationDetails(companyId, projectId, orderId).then((response) => {
                if (response.isSuccess()) {
                   const cancellationDetails: CancelledOrder = response.getEntity();
                   setOrderCancellationDetails(cancellationDetails);
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            })
        }
    }, [showModal, orderCancellationDetails]);

    useEffect(() => {

    }, [orderCancellationDetails]);

    function renderCancelledAndCancellationRequestedInformation(): JSX.Element {

        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>This order was cancelled on</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {dateFormatter(orderCancellationDetails.cancelledOn, { dateStyle: 'short' })} <Trans>at</Trans> {timeFormatter(orderCancellationDetails.cancelledOn, { timeStyle: 'short' })}
                </Col>
               <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Cancellation requested date</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {dateFormatter(orderCancellationDetails.createdOn, { dateStyle: 'short' })}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Cancellation requested time</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {timeFormatter(orderCancellationDetails.createdOn, { timeStyle: 'short' })}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Reason</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {cancelReasonCodeMapper[orderCancellationDetails.reasonCode]}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Comment</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {orderCancellationDetails.reasonComment}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>User</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {orderCancellationDetails.userId}
                </Col>
            </>
        );
    }

    function renderCancellationRequestedInformation(): JSX.Element {

        return (
            <>
               <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Cancellation requested date</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {dateFormatter(orderCancellationDetails.createdOn, { dateStyle: 'short' })}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Cancellation requested time</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {timeFormatter(orderCancellationDetails.createdOn, { timeStyle: 'short' })}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Reason</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {cancelReasonCodeMapper[orderCancellationDetails.reasonCode]}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Comment</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {orderCancellationDetails.reasonComment}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>User</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {orderCancellationDetails.userId}
                </Col>
            </>
        );
    }

    function renderCancelledInformation(): JSX.Element {

        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>This order was cancelled on</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {dateFormatter(orderCancellationDetails.createdOn, { dateStyle: 'short' })} <Trans>at</Trans> {timeFormatter(orderCancellationDetails.createdOn, { timeStyle: 'short' })}
                </Col>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Reason</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {cancelReasonCodeMapper[orderCancellationDetails.reasonCode]}
                </Col>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>User</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {orderCancellationDetails.userId}
                </Col>
            </>
        );
    }

    return (
        !showModal ? null : <Modal
            data-testid="cancel-order-details-modal"
            title={ orderStatus === 'CANCELLATION REQUESTED' ? t`Cancellation Requested` : t`Order cancelled`}
            visible={showModal}
            onCancel={hideModal}
            width={700}
            footer={[
                <Button data-testid="closeButton" key="close" type="primary" onClick={hideModal}>
                    {t`Close`}
                </Button>
            ]}>
            <Row gutter={[48, 16]}>

                {orderStatus === 'ORDER CANCELLED'
                    ? ( orderCancellationDetails.cancelledOn ?
                        renderCancelledAndCancellationRequestedInformation()
                        :
                        renderCancelledInformation()
                    )
                    : renderCancellationRequestedInformation()}

            </Row>
        </Modal>
    );
}

export default CancelOrderDetailsModal;