import { Company, getAuthService, Project, Role, RoleDefinition, UserProfile } from 'src/app-react/services/authService';
import { isObjectEmpty } from 'src/app-react/utils/lib';
import { MARKETS } from '../constants/markets';

export function hasPermissionsToDoInNoTerminalByProject(user: UserProfile, projectId: string, action: string): boolean {
    if (!hasCompanies(user) && !hasCompanyRoles(user) && !hasProjects(user)) {
        return false;
    }

    const companyRoles: string[] = getCompanyRolesNames(user);
    if (!companyRoles) {
        return false;
    }

    const project: Project | undefined = user.companies[0].projects.find((p: Project) => p.projectId === projectId);

    if (project) {
        let haspermission: boolean = false;
        Object.keys(project.projectRoles).forEach((key) => {
            const projectRoles: Partial<Record<Role, RoleDefinition>> = project.projectRoles;
            const permissionsBykey: RoleDefinition | undefined =
                projectRoles[key as keyof Partial<Record<Role, RoleDefinition>>];
            if (permissionsBykey && permissionsBykey['CONCRETE_DELIVERY_LOADS']) {
                const permision: string | undefined = permissionsBykey['CONCRETE_DELIVERY_LOADS'].find(
                    (p: string) => p === action
                );
                if (permision) {
                    haspermission = true;
                }
            }
        });
        return haspermission;
    }

    return false;
}

export function getMarketIdByProject(projectId: string): string {
    let marketId: string = MARKETS.DE;
    const user: UserProfile = getAuthService().user;
    if (hasProjects(user)) {
        const project: Project | undefined = user.companies[0].projects.find((p: Project) => p.projectId === projectId);
        if (project) {
            marketId = project.marketId;
        }
    }

    return marketId;
}

export function getSupplierIdByProject(projectId: string): string {
    let supplierId: string = '';
    const user: UserProfile = getAuthService().user;
    if (hasProjects(user)) {
        const project: Project | undefined = user.companies[0].projects.find((p: Project) => p.projectId === projectId);
        if (project) {
            supplierId = project.supplierSoftware;
        }
    }

    return supplierId;
}

export function userProfile(): UserProfile {
    const userProfile: UserProfile = getAuthService().user;
    return userProfile;
}

export function userRoles(): Role[] {
    const user: UserProfile = userProfile();
    const rolesProfile: string[] = [];

    if (hasCompanies(user)) {
        if (hasCompanyRoles(user)) {
            const companyRoles: string[] = getCompanyRolesNames(user);
            rolesProfile.push(...companyRoles);
        }
        if (hasProjects(user)) {
            for (let project of user.companies[0].projects) {
                const projectRoles: string[] = getProjectRolesNames(project);
                rolesProfile.push(...projectRoles.filter((role) => !rolesProfile.includes(role)));
            }
        }
    }

    return rolesProfile as Role[];
}

export function userRolesByProject(user: UserProfile, projectId: string): Role[] {
    const rolesProfile: string[] = [];

    if (hasCompanies(user)) {
        if (hasCompanyRoles(user)) {
            const companyRoles: string[] = getCompanyRolesNames(user);
            rolesProfile.push(...companyRoles);
        }
        if (hasProjects(user)) {
            const project: Project | undefined = user.companies[0].projects.find(
                (p: Project) => p.projectId === projectId
            );

            if (project) {
                const projectRoles: string[] = getProjectRolesNames(project);
                rolesProfile.push(...projectRoles);
            }
        }
    }

    return rolesProfile as Role[];
}

export function getCompanyByProjectAdmin(user: UserProfile): string {
    return user && user.companies.length > 0 ? user.companies[0].companyId : '';
}

export function isForeman(usrRoles: Role[]): boolean {
    return usrRoles ? usrRoles.some((userRole: Role) => userRole === 'Foreman') : false;
}

export function isProjectAdmin(usrRoles: Role[]): boolean {
    return usrRoles ? usrRoles.some((role: Role) => role === 'ProjectAdmin') : false;
}

export function isProjectManager(usrRoles: Role[]): boolean {
    return usrRoles ? usrRoles.some((role: Role) => role === 'ProjectManager') : false;
}

export function isConcreteEngineer(usrRoles: Role[]): boolean {
    return usrRoles ? usrRoles.some((role: Role) => role === 'ConcreteEngineer') : false;
}

export function loadUserScopesByProject(projectId: string): Role[] {
    const user: UserProfile = getAuthService().user;
    const rolesProfile: string[] = [];

    if (hasCompanies(user)) {
        if (hasCompanyRoles(user)) {
            const companyRoles: string[] = getCompanyRolesNames(user);
            rolesProfile.push(...companyRoles);
        }
        if (hasProjects(user)) {
            const project = user.companies[0].projects.find((p: Project) => p.projectId === projectId);

            if (project) {
                const projectRoles: string[] = getProjectRolesNames(project);
                rolesProfile.push(...projectRoles);
            }
        }
    }

    return rolesProfile as Role[];
}

export function hasCompanyRoles(user: UserProfile): boolean {
    return !isObjectEmpty(user.companies[0].companyRoles);
}

export function hasProjects(user: UserProfile): boolean {
    return !isObjectEmpty(user.companies[0].projects);
}

export function hasCompanies(user: UserProfile): boolean {
    return user && user.companies && user.companies.length > 0;
}

export function getCompanyRolesNames(user: UserProfile): string[] {
    return Object.keys(user.companies[0].companyRoles);
}

export function getProjectRolesNames(project: Project): string[] {
    return Object.keys(project.projectRoles);
}

export function getActiveCompanyName(): string {
    const activeCompany = userProfile().companies[0];
    return activeCompany ? activeCompany.companyName : '';
}

export function getUserCompanies(): Company[] {
    return userProfile().companies;
}