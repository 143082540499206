import { IMarketData } from "..";
import FORMATS from "./dataValues/formats";
import UNITS from "./dataValues/units";

const MARKET_DATA_AUS: IMarketData = {
    constants: {
        units: UNITS,
        formats: FORMATS,
    }
};

export default MARKET_DATA_AUS;
